import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import { resetPassword } from "features/user/userAPI";
import { setUser } from "features/user/userSlice";
import { login, logout } from "features/user/userAPI";
import { validAuthenticationCode } from "app/regex";

/* Import components */
import { Form, Button, Spinner, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreLogin from "components/PreLogin/PreLogin";
import Username from "components/Fields/Username";
import AuthenticationCode from "components/Fields/AuthenticationCode";
import Password from "components/Fields/Password";
import { FocusError } from "focus-formik-error";
import { srAnnounce } from "features/global/functions";

function LoginPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { i18n } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);

	const [isAuthenticationCodeAvailable, setIsAuthenticationCodeAvailable] =
		useState(false);
	const authenticationCodeRef = useRef(null);

	const formikValidateOnBlur = useRef(false);

	const formik = useFormik({
		validateOnChange: false,
		validateOnBlur: formikValidateOnBlur.current,
		initialValues: {
			username: null,
			authenticationCode: null,
			password: null,
		},
		validationSchema: Yup.object({
			username: Yup.string().required(i18n.t("username-required")),
			password: Yup.string()
				.min(8, i18n.t("invalid-password"))
				.required(i18n.t("password-required")),
			...(isAuthenticationCodeAvailable && {
				authenticationCode: Yup.string()
					.matches(validAuthenticationCode, i18n.t("invalid-code"))
					.required(i18n.t("auth-code-required")),
			}),
		}),
		onSubmit: async (values) => {
			setIsLoading(true);
			let response;

			try {
				if (isAuthenticationCodeAvailable) {
					response = await login(
						values.username,
						values.password,
						values.authenticationCode
					);

					if (response.status === 200) {
						// Check if user has admin roles after successful 2FA login
						if (!response.data?.admin_roles?.length) {
							toast.error(i18n.t("access-denied-admin-only"));
							await logout();
							
							// Reset form with empty values
							formik.resetForm({
								values: {
									username: '',
									password: '',
									authenticationCode: ''
								}
							});
							
							setIsAuthenticationCodeAvailable(false);
							setIsLoading(false);
							return;
						}

						// User is admin, complete login
						dispatch(setUser(response.data));
						srAnnounce(i18n.t("sr-annouce-logged-in"));
						setTimeout(() => {
							navigate("/program");
						}, 300);
					}
				} else {
					// First login step - get verification code
					response = await login(values.username, values.password);

					if (response.status === 200) {
						toast.info(i18n.t("authentication-code-emailed"));
						setIsAuthenticationCodeAvailable(true);
						formik.resetForm({
							values: formik.values,
						});
						formikValidateOnBlur.current = false;
					}
				}

				// Handle error cases
				switch (response.status) {
					case 401:
						toast.info(i18n.t("invalid-username-password"));
						break;
					case 403:
						if (
							response.data?.message ===
							"This account has been disabled."
						) {
							toast.info(i18n.t("invalid-account-disabled"));
						}
						if (
							response.data?.message ===
							"Maximum verification attempts exceeded. Please login again."
						) {
							toast.info(
								i18n.t("invalid-authentication-code-attempts")
							);
						}
						break;
					case 422:
						toast.info(i18n.t("invalid-authentication-code"));
						formik.setFieldError(
							"authenticationCode",
							i18n.t("invalid-authentication-code")
						);
						break;
					default:
						if (response.status !== 200) {
							toast.info(i18n.t("invalid-generic"));
						}
						break;
				}
			} catch (error) {
				toast.error(i18n.t("invalid-generic"));
			}

			setIsLoading(false);
		},
	});

	// Create and resend authentication code
	const resendCode = async (e) => {
		e.preventDefault();
		if (isLoading) return;
		setIsLoading(true);

		const response = await resetPassword(formik.values.username);

		setTimeout(() => {
			toast.dismiss();
			switch (response.status) {
				case 200:
					toast.info(i18n.t("authentication-code-emailed"));
					break;
				default: // Generic error message
					toast.info(i18n.t("invalid-generic"));
					break;
			}

			setIsLoading(false);

			// Focus and clear the input element
			setTimeout(() => {
				if (authenticationCodeRef.current) {
					authenticationCodeRef.current.value = ""; // Clear the value
					authenticationCodeRef.current.focus();
				}
			}, 100);
		}, 200);
	};

	// useEffect to automatically trigger the display of authentication code field
	useEffect(() => {
		if (formik.values.authenticationCode) {
			setIsAuthenticationCodeAvailable(true);
		}
	}, [formik.values.authenticationCode]);

	return (
		<PreLogin
			maxWidth={"20rem"}
			alignCenter={true}
			ariaLabel={i18n.t("login")}
		>
			<div className="bg-sdrcc p-3 border rounded">
				<h1 className="h3 mb-3 fw-normal text-center">
					{i18n.t("login")}
				</h1>
				<Form id="loginForm" onSubmit={formik.handleSubmit} noValidate>
					<FocusError formik={formik} />
					<Row className="mt-2 gap-2">
						<Col sm={12}>
							<Username
								placeholder={i18n.t(
									"username-login-placeholder"
								)}
								ariaLabel={i18n.t("username-login-placeholder")}
								showLabel={false}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.username || ""}
								isInvalid={
									formik.touched.username &&
									formik.errors.username
								}
								disabled={
									isLoading || isAuthenticationCodeAvailable
								}
								errors={formik.errors.username}
							></Username>
						</Col>
						<Col sm={12}>
							<Password
								placeholder={i18n.t(
									"password-login-placeholder"
								)}
								ariaLabel={i18n.t("password-login-placeholder")}
								showLabel={false}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.password || ""}
								isInvalid={
									formik.touched.password &&
									formik.errors.password
								}
								disabled={
									isLoading || isAuthenticationCodeAvailable
								}
								errors={formik.errors.password}
							></Password>
						</Col>
						{isAuthenticationCodeAvailable && (
							<Col sm={12}>
								<AuthenticationCode
									ref={authenticationCodeRef}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.authenticationCode &&
										formik.errors.authenticationCode
									}
									disabled={isLoading}
									errors={formik.errors.authenticationCode}
									autoFocus
								></AuthenticationCode>
							</Col>
						)}
						{isAuthenticationCodeAvailable && (
							<Col sm={12} className="mt-0 text-center">
								<a
									href="/"
									onClick={resendCode}
									style={{ fontSize: "12px" }}
									className={`text-decoration-none ${
										isLoading ? " pe-none " : " pe-auto "
									}`}
									aria-disabled={isLoading}
								>
									<FontAwesomeIcon
										icon={faRotate}
										className="me-2"
									/>
									{i18n.t("resend-code")}
								</a>
							</Col>
						)}
						<Col className="d-grid gap-2 mt-1">
							<Button
								type="submit"
								variant="primary"
								size="lg"
								onClick={() => {
									formikValidateOnBlur.current = true;
								}}
								disabled={isLoading}
							>
								{!isLoading && i18n.t("sign-in")}
								{isLoading && i18n.t("loading")}
								{isLoading && (
									<Spinner
										as="span"
										style={{
											height: 25,
											width: 25,
											position: "absolute",
											right: 20,
											marginTop: 2,
										}}
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								)}
							</Button>
							<Button
								type="button"
								variant="secondary"
								size="lg"
								disabled={isLoading}
								onClick={(e) => {
									//navigate("/registration");
									toast.dismiss();
									toast.warning(i18n.t("registration-disabled-message"));
								}}
							>
								{i18n.t("create-account")}
							</Button>
						</Col>
						<Col sm={12} className="mt-1 text-center">
							<Link
								to="/recovery"
								relative="path"
								className="text-decoration-none"
								disabled={isLoading}
								aria-label={i18n.t(
									"forgot-password-aria-label"
								)}
							>
								{i18n.t("forgot-password")}
							</Link>
						</Col>
					</Row>
				</Form>
			</div>
			<ToastContainer position="bottom-right" />
		</PreLogin>
	);
}

export default LoginPage;
