import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";


function Header() {
	const { i18n } = useTranslation();
	console.log('language:', i18n.language);
    return (
        <>
            <Alert className="text-center" variant="warning" style={{ textWrap: "balance" }}>
				<Trans i18nKey="prelogin-alert-message"></Trans>
            </Alert>
			<header className="container text-center mt-4 mb-4" style={{ maxWidth: "20rem" }}>
                <img src="/images/logo.svg" className="img-fluid mb-4" alt="" style={{ height: "141px"}} />
            </header>
        </>
    );
}

export default Header;
