import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, setUser } from "features/user/userSlice";
import { selectLanguage } from "features/language/languageSlice";
import { getUser } from "features/user/userAPI";
import LoginPage from "./pages/PreLogin/Login";
import ProfilePage from "./pages/PostLogin/Profile/Profile";
import AdminPage from "./pages/PostLogin/Admin/Admin";
import LanguagePage from "./pages/PreLogin/Language";
import RegistrationPage from "./pages/PreLogin/Registration/Registration";
import { useTranslation } from "react-i18next";
import RecoveryPage from "./pages/PreLogin/Recovery";
import ProgramPage from "./pages/PostLogin/Program/Program";
import ResourcesPage from "./pages/PostLogin/Resources/Resources";
import HelpPage from "./pages/PostLogin/Help/Help";
import LoadingSpinner from "components/LoadingSpinner";

function App() {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const language = useSelector(selectLanguage);
	const [loading, setLoading] = useState(true);
	const { i18n } = useTranslation();

	// set the lang attribute of html element to selected locale
	useEffect(() => {
		document.documentElement.lang = i18n.language;
		document.title = i18n.t("abuse-free-sport-participant-consent-form");
		document
			.getElementById("root")
			.setAttribute("aria-label", i18n.t("abuse-free-sport"));
	}, [i18n.language]);

	// get user auth
	useEffect(() => {
		const fetchUser = async () => {
			const user = await getUser();
			if (user && !user.error) {
				dispatch(setUser(user));
			}
			setLoading(false);
		};
		fetchUser();
	}, [dispatch]);

	if (loading) {
		return <LoadingSpinner isLoading={true} />;
	}

	const RootRedirect = () => {
		if (user) {
			return <Navigate to="/program" />;
		} else if (!language) {
			return <Navigate to="/language" />;
		} else {
			return <Navigate to="/login" />;
		}
	};
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<RootRedirect />} />
				<Route path="/program" element={<ProgramPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/profile" element={<ProfilePage />} />
				<Route path="/language" element={<LanguagePage />} />
				{/* <Route path="/registration" element={<RegistrationPage />} /> */}
				<Route path="/recovery" element={<RecoveryPage />} />
				<Route path="/admin" element={<AdminPage />} />
				<Route path="/resources" element={<ResourcesPage />} />
				<Route path="/help" element={<HelpPage />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
